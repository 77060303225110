export const colors = {
  accentOrange: "#FF804A",
  navBlue: "#292F6C",
  offWhiteText: "#F4F3EF",
  background: "#F4F6F8",
  srokeGray: "#C6CDD4",
  offBlackText: "#202223",
  helpText: "#6F7C88",
  ctaBlue: "#5B6DBE",
  blueBorder: "#424EA8",
  linkBlue: "#3773D9",
  alertRed: "#CE462B",
  redBorder: "#A23721",
  badgeGreen: "#BFE2B5",
  greenText: "#515E4C",
  goodGreen: "#055936",
  betterGreen: "#0A9C07",
  warningYellow: "#FFEFC7",
  yellowBorder: "#FFE6A7",
  uiGray: "#888D8A",
  warningIconYellow: "#FFBD15",
};

export const transparentColors = {
  accentOrange: "#FF804A26",
  navBlue: "#292F6C26",
  offWhiteText: "#F4F3EF26",
  background: "#F4F6F826",
  srokeGray: "#C6CDD426",
  offBlackText: "#20222326",
  helpText: "#6F7C8826",
  ctaBlue: "#5B6DBE26",
  blueBorder: "#424EA826",
  linkBlue: "#3773D926",
  alertRed: "#CE462B26",
  redBorder: "#A2372126",
  badgeGreen: "#BFE2B526",
  greenText: "#515E4C26",
  goodGreen: "#05593626",
  betterGreen: "#0A9C0726",
  warningYellow: "#FFEFC726",
  yellowBorder: "#FFE6A726",
  uiGray: "#888D8A26",
  warningIconYellow: "#FFBD1526",
};
